<template>
  <v-col :cols="6" md="3">
    <v-card style="min-height:130px" @click="dialogActive = true">
      <v-card-text v-if="person.category === 'student'">
        <img :src="person.avatar || '/img/no.jpg'" style="max-height:150px;max-width:120px;width:100%;margin:auto;display:block" />
        <h4 style="text-align:center">{{ person.text }}</h4>
      </v-card-text>
      <v-card-text v-else-if="person.category === 'applicant'">
        <h4>{{ person.name }}</h4>
        <p>New incoming student</p>
      </v-card-text>
      <v-card-text v-else-if="person.category === 'unknown'">
        <p>No planned roommate</p>
      </v-card-text>
      <v-card-title v-else style="word-break:normal">Select a roommate</v-card-title>
    </v-card>
    <v-dialog v-model="dialogActive" width="400">
      <v-card>
        <v-card-title>Add a Roommate</v-card-title>
        <v-card-text>
          <form-radio v-model="roommateOption" :items="radioOptions"></form-radio>
          <directory-search v-if="roommateOption === 'student'" v-model="student" :filter="{ person: 'Student', gender: sex, _id: { $nin: skipUsers } }" search-label="Roommate Search" hide-details></directory-search>
          <v-text-field v-else-if="roommateOption === 'applicant'" v-model="name" label="Incoming Student's Name" outlined hide-details></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="done">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogActive = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { computed, ref } from '@vue/composition-api'
export default {
  props: {
    person: {
      type: Object,
      default: () => {
        return {}
      }
    },
    sex: {
      type: String,
      required: true
    },
    roommates: {
      type: Array,
      default: () => { return [] }
    }
  },
  components: {
    formRadio: () => import('@/components/forms/inputs/Radio'),
    directorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)
    const roommateOption = ref(props.person.category || '')
    const radioOptions = ref([
      { value: 'student', text: 'Room with a returning student' },
      { value: 'applicant', text: 'Room with an incoming student' },
      { value: 'unknown', text: 'I do not have a planned roommate' }
    ])
    const student = ref(props.person || {})
    const name = ref(props.person.name || '')

    const skipUsers = computed(() => {
      const arr = []
      if (props.roommates.length > 0) {
        for (const { category, value } of props.roommates) {
          if (category === 'student' && value != null && value !== '' && (!('value' in props.person) || value !== props.person.value)) arr.push(value)
        }
      }
      return arr
    })

    function done () {
      const category = roommateOption.value
      switch (category) {
        case 'student':
          emit('update', { category, ...student.value })
          dialogActive.value = false
          break
        case 'applicant':
          emit('update', { category, name: name.value })
          dialogActive.value = false
          break
        case 'unknown':
          emit('update', { category, name: 'No planned roommate' })
          dialogActive.value = false
          break
      }
    }

    return {
      dialogActive,
      roommateOption,
      radioOptions,
      student,
      name,
      skipUsers,
      done
    }
  }
}
</script>
